import React from "react"
import { Box, Text } from "@shopify/polaris"

const FieldTitle = (props) => {
  const { title } = props
    return (
    <Box paddingBlockEnd="200">
      <Text variant="bodyMd" as="span" fontWeight="medium">{title}</Text>
    </Box>
    )
}

export default FieldTitle